import { getApiUrl } from "./api";

export type Service = "twitch";

export type User = {
  id: string;
  displayName: string;
  email: string;
};

export function getLoginUrl(service: Service) {
  return getApiUrl(`/auth/${service}/login`);
}

export function getLogoutUrl() {
  return getApiUrl(`/auth/logout`);
}
